<template>
  <div class="card confirm p-4 text-center">
    <Title v-if="title" :text="title" />
    <TextBlock v-if="text" variant="secondary" :text="text" />
    <div class="mt-4">
      <HeraFormBtn
        class="h-mr-2"
        :disabled="isLoading"
        @click.prevent="$emit('cancel')"
      >
        {{
          cancelButtonText ||
          $t('_web_stage_start_modal_confirmation_cancel', 'Cancel')
        }}
      </HeraFormBtn>
      <HeraFormBtn
        class="btn-primary"
        :loading="isLoading"
        @click.prevent="$emit('confirm')"
      >
        {{
          confirmButtonText ||
          $t('_web_stage_start_modal_confirmation_accept', 'Accept')
        }}
      </HeraFormBtn>
    </div>
  </div>
</template>

<script>
import HeraFormBtn from '@/components/form/HeraFormBtn';
import TextBlock from '@/components/text/TextBlock';
import Title from '@/components/text/Title';

export default {
  name: 'HeraConfirm',
  components: { HeraFormBtn, Title, TextBlock },

  props: {
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    cancelButtonText: {
      type: String,
      default: '',
    },
    confirmButtonText: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.card.confirm {
  background: $element-bg-color;
  border-radius: 0.25rem;

  &:after {
    content: '';
    @include primary-background-gradient(135deg);
    position: absolute;
    top: -2px;
    bottom: -2px;
    left: -2px;
    right: -2px;
    border-radius: 4px;
    z-index: -1;
  }

  button {
    min-width: 120px;
  }
}
</style>
