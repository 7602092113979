<template>
  <HeraConfirm
    :text="modalTitle"
    :cancel-button-text="
      cancelBtnLabel || $t('_web_leave-confirm-cancel', 'Cancel')
    "
    :confirm-button-text="confirmBtnLabel || $t('_web_leave-confirm-ok', 'Ok')"
    @cancel="cancelLeave"
    @confirm="confirmLeave"
  />
</template>

<script>
import HeraConfirm from './HeraConfirm';
import { mapActions } from 'vuex';

export default {
  name: 'HeraConfirmLeave',
  components: { HeraConfirm },

  props: {
    title: {
      type: String,
      default: '',
    },
    cancelBtnLabel: {
      type: String,
      default: '',
    },
    confirmBtnLabel: {
      type: String,
      default: '',
    },
    nextRoute: {
      type: Function,
      required: true,
    },
  },

  computed: {
    modalTitle() {
      return (
        this.title ||
        this.$t(
          '_web_leave-confirm-title',
          'If you navigate away from this page without saving your data, the changes will be lost'
        )
      );
    },
  },

  methods: {
    ...mapActions(['closeModal']),

    cancelLeave() {
      this.closeModal(false);
    },

    confirmLeave() {
      this.closeModal(false);
      this.$emit('confirmLeave');
      this.nextRoute();
    },
  },
};
</script>
